<!-- 充值 -->
<template>
  <div class="recharge-page">
    <div class="content">
      <div class="select">
        <div class="select-coin-box">
          <div class="current-coin">
            <img src="../assets/images/usdt-logo.png" alt="" />
            <div class="coin-name font-m">USDT-ERC20</div>
          </div>
          <div class="arraw">
            <img src="../assets/images/icon-arraw-right.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="main-box">
        <div class="title font-m font-bold">{{ $i18n.t("re_into_wallet") }}</div>
        <!-- <div class="recharge-sub">最小充值额 10 ETH</div> -->
        <div class="recharge-tip">{{ $i18n.t("re_using_code") }}</div>
        <div class="qrcode-box">
          <div class="qrcode" ref="qrCodeUrl"></div>
          <!-- <img src="../assets/images/pic.png" alt="" /> -->
          <!-- <div class="save-btn">保存二维码</div> -->
        </div>
      </div>
      <div class="copy-box">
        <!-- <div class="add-title font-m">ETH充值地址</div> -->
        <div class="copy-line">
          <div class="word f28 color-666 font-m">{{ $i18n.t("re_wallet_address") }}</div>
          <div class="copy" v-show="repbAuthAddress">
            <img src="../assets/images/icon-copy.svg" alt="" />
            <div
              class="f24"
              id="tag-copy"
              :data-clipboard-text="repbAuthAddress"
              @click="copyActiveCode"
            >
              Copy
            </div>
          </div>
        </div>
        <div class="address">
          <div class="word">{{ repbAuthAddress }}</div>
        </div>
      </div>
      <div class="input-block">
        <div class="label f28 font-m color-666">{{ $i18n.t("re_recharge_quantity") }}</div>
        <div class="input-box">
          <input type="text" v-model="money" class="f28" placeholder="≥100USDT" />
          <div class="unit f28 color-333 font-m">USDT</div>
        </div>
        <div class="input-tip color-666 f28">
          {{ $i18n.t("re_will_get") }}<span class="font-b font-bold">{{ money || 0 }} USDT</span>
        </div>
        <div class="switch">
          <van-switch
            v-model="checked"
            size="20px"
            disabled
            active-color="#0CD595"
            inactive-color="#E8E8E9"
          />
          <div class="label f24 color-666">{{ $i18n.t("re_que") }}</div>
        </div>
      </div>
      <div class="input-block" style="margin-top: 0.48rem">
        <div class="label f28 font-m color-666">{{ $i18n.t("re_credentials") }}</div>
        <div class="upload-box">
          <van-uploader
            @oversize="onOversize"
            @delete="deleteClzp"
            :before-read="beforeRead"
            :after-read="clzpAfterRead"
            v-model="fileList"
            :upload-text="uploadText"
            max-count="1"
            upload-icon="photo-o"
          />
        </div>
      </div>
      <div class="btn confrim-btn" @click="submitPost">{{ $i18n.t("re_confirm_recharge") }}</div>
    </div>
  </div>
</template>

<script>
import Clipboard from "clipboard";
import QRCode from "qrcodejs2";
import config from "@/config";
export default {
  data() {
    return {
      currency: config.currency,
      money: null,
      repbAuthAddress: "",
      checked: true,
      // fileList: [{url: "https://img01.yzcdn.cn/vant/leaf.jpg"}],
      fileList: [],
      filePath: "",
      clzpfilePath: "", //删除所需参数
    };
  },
  computed: {
    uploadText() {
      return this.$i18n.t("re_tips2");
    },
  },
  created() {
    this.getRechargeAddress();
  },
  methods: {
    copyActiveCode() {
      if (!this.repbAuthAddress) {
        this.$toast(this.$i18n.t("in_copy_again"));
        return;
      }
      const clipboard = new Clipboard("#tag-copy");
      clipboard.on("success", () => {
        this.$toast(this.$i18n.t("in_copy_success"));
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制
        this.$toast(this.$i18n.t("in_copy_failed"));
        // 释放内存
        clipboard.destroy();
      });
    },
    creatQrCode(address) {
      new QRCode(this.$refs.qrCodeUrl, {
        text: address,
        width: 100,
        height: 100,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    submitPost() {
      let that = this;
      if (this.money == null || this.money == "") {
        this.$toast(this.$i18n.t("re_tips1"));
        return;
      }
      if (this.filePath == null || this.filePath == "") {
        this.$toast(this.$i18n.t("re_tips2"));
        return;
      }
      this.$http
        .addRecharge({
          currency: "USDT",
          address: that.repbAuthAddress,
          money: parseFloat(that.money),
          detail: that.filePath,
        })
        .then((res) => {
          if (res.code == 0) {
            that.$toast(res.msg);
            that.$router.go(-1);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRechargeAddress() {
      let that = this;
      this.$http.getRechargeAddress().then((res) => {
        console.log(res);
        if (res.code === 0) {
          that.repbAuthAddress = res.data.repbAuthAddress;
          this.creatQrCode(that.repbAuthAddress);
        }
      });
    },
    //校验上传图片大小
    onOversize(file) {
      console.log(file);
      this.$toast(this.$i18n.t("file_no_5mb"));
    },
    //校验图片的格式
    beforeRead(file) {
      if (!/(jpg|jpeg|png|JPG|PNG)/i.test(file.type)) {
        // this.$toast("请上传正确格式的图片");
        this.$toast(this.$i18n.t("upload_pic_tips"));
        return false;
      }
      return true;
    },
    //照片上传事件方法
    clzpAfterRead(file) {
      let that = this;
      // 上传状态提示开启
      file.status = "uploading";
      file.message = this.$i18n.t("file_uploading");
      // 创建一个空对象实例
      let formData = new FormData();
      // 调用append()方法添加数据
      formData.append("file", file.file);
      this.$http.upLoadImg(formData).then((res) => {
        if (res.code == 0) {
          // 上传状态提示关闭
          file.status = "done";
          this.$toast(this.$i18n.t(res.msg));
          //this.clzpfilePath = res.data.relativePath; //删除所需参数
          that.filePath = res.data.file.url;
        }
      });
    },
    //照片删除事件方法
    deleteClzp() {
      // filePath：删除所需的参数
      // deleteFiles({filePath: this.clzpfilePath}).then((res) => {
      //   if (res.code == "10000") {
      //     this.$toast("删除成功！");
      //   }
      // });
    },
  },
};
</script>

<style lang="less">
.recharge-page {
  padding-top: 56px;
  background: #f5f5f5;
  .content {
    padding: 0.48rem;
    background: #fff;
    margin-bottom: 0.12rem;
    .select {
      position: relative;
      margin-bottom: 0.48rem;
      .select-coin-box {
        position: relative;
        padding: 0.24rem 0.24rem;
        border: 1px solid #eeeeee;
        border-radius: 0.24rem;
        .current-coin {
          display: flex;
          img {
            width: 0.48rem;
            height: 0.48rem;
          }
          .coin-name {
            color: #666;
            font-size: 0.28rem;
            margin-left: 0.2rem;
            line-height: 0.48rem;
          }
        }
        .arraw {
          position: absolute;
          right: 0.24rem;
          top: 0.24rem;
          transform: rotate(90deg);
          img {
            width: 0.48rem;
            height: 0.48rem;
          }
        }
      }
      .select-list {
        width: 100%;
        position: absolute;
        .select-option {
          display: flex;
          padding: 0.2rem 0.24rem;
          line-height: 0.4rem;
          border: 1px solid #eee;
          img {
            width: 0.36rem;
            height: 0.36rem;
          }
          .coin-name {
            color: #666;
            font-size: 0.24rem;
            margin-left: 0.2rem;
          }
        }
      }
    }
    .main-box {
      .title {
        font-size: 0.4rem;
        line-height: 0.6rem;
        color: #333;
        margin-bottom: 0.1rem;
      }
      .recharge-sub {
        line-height: 0.4rem;
        color: #999;
        font-size: 0.28rem;
        // margin-bottom: 0.1rem;
      }
      .recharge-tip {
        line-height: 0.4rem;
        color: #999;
        font-size: 0.28rem;
      }
      .qrcode-box {
        text-align: center;
        .qrcode {
          margin: 0.4rem 0 0.4rem 0;
        }
        img {
          width: 3.2rem;
          height: 3.2rem;
          margin: 0 auto;
        }
        .save-btn {
          font-size: 0.3rem;
          line-height: 0.8rem;
          color: #333;
          border: 1px solid #eee;
          border-radius: 0.8rem;
          display: inline-block;
          padding: 0 0.4rem;
        }
      }
    }
    .common-box {
      padding: 0.24rem 0.3rem;
      border: 1px solid #eeeeee;
      border-radius: 0.24rem;
      margin-bottom: 0.3rem;
    }
    .copy-tip {
      font-size: 0.28rem;
      line-height: 0.4rem;
      color: #999;
      margin-bottom: 0.3rem;
      text-align: left;
    }
    .copy-box {
      text-align: left;
      margin-top: 0.3rem;
      .copy-line {
        display: flex;
        justify-content: space-between;
        line-height: 0.32rem;
        margin-bottom: 0.2rem;
        .copy {
          display: flex;
          color: #0cd595;
          img {
            margin-right: 0.1rem;
          }
        }
      }
      .add-title {
        line-height: 0.4rem;
        font-size: 0.28rem;
        color: #666;
        margin-bottom: 0.2rem;
      }
      .address {
        padding: 0.24rem 0.3rem;
        border: 1px solid #eeeeee;
        border-radius: 0.24rem;
        .word {
          color: #333;
          font-size: 0.28rem;
          line-height: 0.4rem;
        }
      }
    }
    .line-box {
      .line {
        display: flex;
        font-size: 0.28rem;
        line-height: 0.6rem;
        .word {
          color: #666;
        }
        .value {
          color: #999;
          margin-left: 0.2rem;
        }
      }
    }
    .input-block {
      margin-top: 0.3rem;
      .label {
        text-align: left;
        font-weight: 500;
        line-height: 0.48rem;
        margin-bottom: 0.1rem;
      }
      .input-box {
        border: 1px solid #eee;
        padding: 0 0.2rem;
        border-radius: 0.24rem;
        display: flex;
        input {
          height: 0.88rem;
          border: none;
          flex: 1;
        }
        .unit {
          margin-left: 0.3rem;
          line-height: 0.88rem;
        }
      }
      .input-tip {
        text-align: left;
        margin-top: 0.2rem;
        span {
          padding-left: 0.1rem;
        }
      }
      .switch {
        display: flex;
        margin-top: 0.2rem;
        .label {
          margin-left: 0.2rem;
        }
      }
      .upload-box {
        display: flex;
        justify-content: flex-start;
        .van-uploader {
          width: calc(100vw - 0.96rem);
          height: 3.6rem;
          .van-uploader__wrapper {
            .van-uploader__upload {
              width: calc(100vw - 0.96rem);
              height: 3.6rem;
              margin: 0 0;
            }
            .van-uploader__preview {
              width: calc(100vw - 0.96rem);
              height: 3.6rem;
              .van-uploader__preview-image {
                width: calc(100vw - 0.96rem);
                height: 3.6rem;
              }
            }
          }
          .van-uploader__input {
            width: calc(100vw - 0.96rem);
            height: 3.6rem;
          }
        }
      }
    }
    .confrim-btn {
      width: 100%;
      margin-top: 0.72rem;
    }
  }
}
</style>
